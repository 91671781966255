import { useEffect } from 'react';

export function useVideoPlay(
  index: number,
  activeSlide: number,
  videoEl: React.RefObject<HTMLVideoElement>
): void {
  useEffect(() => {
    if (!videoEl.current) {
      return;
    }

    if (index === activeSlide) {
      videoEl.current.play();
    } else {
      videoEl.current.pause();
      videoEl.current.currentTime = 0;
    }
  }, [activeSlide]);
}
