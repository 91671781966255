import React, { useRef } from 'react';
import { useVideoPlay } from 'hooks/useVideoPlay';

import styles from './ViewItem.module.scss';

type PropsType = {
  index: number;
  activeSlide: number;
  endedVideoHandler: () => void;
  video: string;
};

const ViewItem: React.FC<PropsType> = ({ endedVideoHandler, activeSlide, index, video }) => {
  const videoEL = useRef<HTMLVideoElement>(null);

  useVideoPlay(index, activeSlide, videoEL);

  return (
    <div className={styles.item}>
      <div className={styles.video}>
        <video ref={videoEL} src={video} autoPlay muted playsInline onEnded={endedVideoHandler} />
      </div>
      <div className={styles.phone} />
    </div>
  );
};
ViewItem.displayName = 'FeaturesViewItem';

export default ViewItem;
