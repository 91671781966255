import React from 'react';
import classnames from 'classnames';

import { SectionIdEnum } from 'store/rootTypes';
import UIBackground from '../ui/Background';
import styles from './Drivers.module.scss';
import { ComponentProps } from './types';

const Drivers: React.FC<ComponentProps> = ({ drivers }) => {
  if (!drivers) {
    return null;
  }
  return (
    <section className={styles.drivers} id={SectionIdEnum.Drivers}>
      <UIBackground video={drivers.video} />
      {drivers.photo && (
        <img className={styles.image} src={drivers.photo} alt={drivers.photo_alt} />
      )}
      {drivers.title && (
        <h3 className={classnames(styles.title, 'section-title')}>{drivers.title}</h3>
      )}
      {drivers.content && <p className={classnames(styles.text, 'text')}>{drivers.content}</p>}
    </section>
  );
};

export default Drivers;
