import { RootReducer } from './rootReducer';

export type InferValueTypes<T> = T extends { [key: string]: infer U } ? U : never;
export type InitialState = ReturnType<typeof RootReducer>;

export enum SectionIdEnum {
  Startup = 'startup',
  Service = 'service',
  Promo = 'promo',
  Tariffs = 'tariffs',
  Features = 'features',
  Drivers = 'drivers',
  Privileges = 'privileges',
  Download = 'download',
  Disclaimer = 'disclaimer',
}

export enum EnumViewType {
  Image = 'image',
  Video = 'video',
}
