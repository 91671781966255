import { combineReducers } from 'redux';
import app from './app';
import cities from './cities';

export const RootReducer = combineReducers({
  app,
  cities,
});

const createRootReducer = () => RootReducer;

export default createRootReducer;
