import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SectionIdEnum } from 'store/rootTypes';
import { ComponentProps } from '../CityPage';

import Header from '../../components/Header';
import Hero from '../../components/Hero';
import About from '../../components/About';
import Promo from '../../components/Promo';
import Tariffs from '../../components/Tariffs';
import Features from '../../components/Features';
import Drivers from '../../components/Drivers';
import Privilege from '../../components/Privilege';
import Sending from '../../components/Sending';
import Footer from '../../components/Footer';

const CityPage: React.FC<ComponentProps> = ({
  fetchMainInfo,
  blocks,
  privileges,
  scrollPrivilegesSection,
}) => {
  const [height, setHeight] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const [isSend, setIsSend] = useState(false);
  const mainRef = useRef<HTMLDivElement>(null);
  const { city } = useParams();
  useEffect(() => {
    if (blocks.length === 0) {
      fetchMainInfo(city);
    }

    document.addEventListener('scroll', scrollToPrivileges);
    return () => {
      document.removeEventListener('scroll', scrollToPrivileges);
    };
  }, []);

  const scrollToPrivileges = useCallback(() => {
    setScrollY(window.scrollY);
  }, []);

  const sendMetrik = () => {
    scrollPrivilegesSection();
    setIsSend(true);
  };

  useEffect(() => {
    if (privileges && mainRef.current) {
      const privilegesBlock = mainRef.current.querySelector(`#${SectionIdEnum.Privileges}`);
      setHeight(privilegesBlock ? privilegesBlock.getBoundingClientRect().top + window.scrollY : 0);
    } else {
      setHeight(0);
    }
  }, [privileges]);

  useEffect(() => {
    if (height > 0 && scrollY > height - 200 && !isSend) {
      sendMetrik();
    }
  }, [scrollY]);

  return (
    <div className="home-page">
      <Header />
      <main className="main" ref={mainRef}>
        <Hero />
        <About />
        <Promo />
        <Tariffs />
        <Features />
        <Drivers />
        <Privilege />
        <Sending />
      </main>
      <Footer />
    </div>
  );
};

export default CityPage;
