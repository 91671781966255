import { connect } from 'react-redux';
import { InitialState, SectionIdEnum } from 'store/rootTypes';
import { getBlockById, getIsMobile } from 'store/app';
import Tariffs from './Tariffs';

const mapStateToProps = (state: InitialState) => ({
  tariffs: getBlockById(state, SectionIdEnum.Tariffs),
  isMobile: getIsMobile(state),
});

const mapDispatchToProps = {};

export * from './types';
export default connect(mapStateToProps, mapDispatchToProps)(Tariffs);
