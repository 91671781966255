import { connect, ConnectedProps } from 'react-redux';
import { InitialState } from 'store/rootTypes';
import { getMeta, fetchMetaInfo, CSRFget } from 'store/app';
import { getActiveCity } from 'store/cities';
import App from './App';

const mapStateToProps = (state: InitialState) => ({
  meta: getMeta(state),
  activeCity: getActiveCity(state),
});
const mapDispatchToProps = { fetchMetaInfo, CSRFget };

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;
export type ComponentProps = PropsFromRedux;

export default connector(App);
