import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { EnumViewType } from 'store/rootTypes';

type ReturnHooks = {
  clearTimeouted: () => void;
  timeoutNextSlide: () => void;
  nextSlide: () => void;
};

export function useTimeoutNextSlide(
  list: Array<any>,
  slider: React.RefObject<Slider>,
  duration: number = 5000
): ReturnHooks {
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const timeoutNextSlide = (): void => {
    clearTimeouted();
    setTimer(setTimeout((): void => nextSlide(), duration));
  };

  const nextSlide = (): void => {
    clearTimeouted();
    // eslint-disable-next-line no-unused-expressions
    slider.current?.slickNext();
  };

  const clearTimeouted = (): void => {
    clearTimeout(timer as NodeJS.Timeout);
  };

  useEffect(() => {
    if (list && list[0].type === EnumViewType.Image) {
      timeoutNextSlide();
    }
  }, []);

  return {
    clearTimeouted,
    timeoutNextSlide,
    nextSlide,
  };
}
