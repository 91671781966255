import { ArrayCities } from './types';

export const ActionConst = {
  SET_CITIES: '@@/city/SET_CITIES' as const,
  SET_ACTIVE_CITY: '@@/city/SET_ACTIVE_CITY' as const,
  SET_DEFAULT_CITY: '@@/city/SET_DEFAULT_CITY' as const,
  SET_DETECT_CITY: '@@/city/SET_DETECT_CITY' as const,
};

export const setCities = (cities: ArrayCities) => ({
  type: ActionConst.SET_CITIES,
  payload: cities,
});

export const setActiveCity = (city: string) => ({
  type: ActionConst.SET_ACTIVE_CITY,
  payload: city,
});

export const setDefaultCity = (city: string) => ({
  type: ActionConst.SET_DEFAULT_CITY,
  payload: city,
});

export const setDetectCity = (city: string) => ({
  type: ActionConst.SET_DETECT_CITY,
  payload: city,
});

export const actions = { setCities, setActiveCity, setDefaultCity, setDetectCity };
