import ym from 'react-yandex-metrika';

export const metrikSmsSended = () => () => {
  ym('reachGoal', 'business_main_app_sms_sended');
};

export const clickIosLink = (event) => () => {
  event.preventDefault();
  ym('reachGoal', 'business_main_clck_ios');
  const originalLink = event.target.closest('a').getAttribute('href');
  const targetLink = new URL(originalLink);
  const currentParams = new URL(document.location).searchParams;
  const adj_adgroup = currentParams.get('adj_adgroup');
  const adj_creative = currentParams.get('adj_creative');
  if (adj_adgroup) {
    targetLink.searchParams.set('adj_adgroup', adj_adgroup);
  }
  if (adj_creative) {
    targetLink.searchParams.set('adj_creative', adj_creative);
  }
  window.location.href = targetLink.href;
  return false;
};

export const clickAndroidLink = (event) => () => {
  event.preventDefault();
  ym('reachGoal', 'business_promo_clck_android');
  const originalLink = event.target.closest('a').getAttribute('href');
  const targetLink = new URL(originalLink);
  const currentParams = new URL(document.location).searchParams;
  const adj_adgroup = currentParams.get('adj_adgroup');
  const adj_creative = currentParams.get('adj_creative');
  if (adj_adgroup) {
    targetLink.searchParams.set('adj_adgroup', adj_adgroup);
  }
  if (adj_creative) {
    targetLink.searchParams.set('adj_creative', adj_creative);
  }
  window.location.href = targetLink.href;
  return false;
};

export const clickDownloadLink = (event) => () => {
  event.preventDefault();
  ym('reachGoal', 'app_download_click');
  const originalLink = event.target.closest('a').getAttribute('href');
  const targetLink = new URL(originalLink);
  const currentParams = new URL(document.location).searchParams;
  const adj_adgroup = currentParams.get('adj_adgroup');
  const adj_creative = currentParams.get('adj_creative');
  if (adj_adgroup) {
    targetLink.searchParams.set('adj_adgroup', adj_adgroup);
  }
  if (adj_creative) {
    targetLink.searchParams.set('adj_creative', adj_creative);
  }
  window.location.href = targetLink.href;
  return false;
};

export const scrollPrivilegesSection = () => () => {
  ym('reachGoal', 'privileges_section_view');
};
