import React from 'react';
import { isBrowser, isTablet, isIOS, isAndroid, MobileOnlyView } from 'react-device-detect';
import UIButton from '../../ui/Button';

import appleStore from '../../../assets/img/global/applestore.svg';
import googlePlay from '../../../assets/img/global/googleplay.svg';
import styles from './Links.module.scss';

type ComponentProps = {
  appleStoreLink: string;
  googlePlayLink: string;
  clickAndroidLink: () => void;
  clickDownloadLink: () => void;
  clickIosLink: () => void;
};

const Links: React.FC<ComponentProps> = ({
  appleStoreLink,
  googlePlayLink,
  clickAndroidLink,
  clickDownloadLink,
  clickIosLink,
}) => {
  const mobileStoreLink: string = isIOS ? appleStoreLink : googlePlayLink;

  const appleStoreView = (): JSX.Element => (
    <a
      className={styles.link}
      href={appleStoreLink}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="apple store"
      onClick={clickIosLink}
    >
      <img src={appleStore} alt="icon apple store" />
    </a>
  );

  const googlePlayView = (): JSX.Element => (
    <a
      className={styles.link}
      href={googlePlayLink}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="google play"
      onClick={clickAndroidLink}
    >
      <img src={googlePlay} alt="icon google play" />
    </a>
  );
  return (
    <div className={styles.links}>
      <MobileOnlyView>
        <UIButton
          tag="a"
          target="_blank"
          href={mobileStoreLink}
          otherClass={styles.mobileLink}
          onClick={clickDownloadLink}
        >
          Скачать приложение
        </UIButton>
      </MobileOnlyView>
      {isBrowser ? (
        <>
          {appleStoreView()}
          {googlePlayView()}
        </>
      ) : isTablet ? (
        <>
          {isIOS && appleStoreView()}
          {isAndroid && googlePlayView()}
        </>
      ) : null}
    </div>
  );
};

export default Links;
