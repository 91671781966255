import { AxiosResponse } from 'axios';
import { setActiveCity, setCities, setDefaultCity, setDetectCity } from '../cities';
import { metrikSmsSended } from '../metriks';
import {
  fetchFailure,
  fetchSuccess,
  fetchRequest,
  setAnchors,
  setBlocks,
  setMeta,
  setIsStatusForm,
  setCaptcha,
  setIsErrorCaptcha,
  setСspHeaders,
} from './actions';
import {
  ThunkType,
  FetchMainInfoResponseType,
  apiMainInfoQueryType,
  FormStatus,
  FormRequestType,
  errorNotExistMessage,
  DetectCityType,
  MetaInfoType,
} from './types';
import { apiMainInfo, apiMetaInfo, apiPostPhone, apiCSRFget, apiDetectCity } from './api';

export const fetchMainInfo = (
  slug?: string,
  query?: apiMainInfoQueryType,
  cookie?: string
): ThunkType => async (dispatch) => {
  dispatch(fetchRequest());
  await apiMainInfo(slug, query, cookie)
    .then((res: FetchMainInfoResponseType) => {
      if (!res.exist_city) {
        dispatch(fetchFailure({ message: errorNotExistMessage }));
      }
      dispatch(setDefaultCity(res.default_city));
      dispatch(setActiveCity(slug && slug !== 'not-found' ? slug : res.default_city));
      dispatch(setAnchors(res.anchors));
      dispatch(setBlocks(res.blocks));
      dispatch(setCities(res.cities));
      dispatch(fetchSuccess());
    })
    .catch((error: any) => {
      dispatch(fetchFailure(error));
    });
};

export const fetchMetaInfo = (slug?: string): ThunkType => async (dispatch) => {
  await apiMetaInfo(slug).then((res: AxiosResponse) => {
    dispatch(setMeta(res.data as MetaInfoType));
    dispatch(
      setСspHeaders({
        'content-security-policy': res.headers['content-security-policy'],
        'content-security-policy-report-only': res.headers['content-security-policy-report-only'],
      })
    );
  });
};

export const postPhone = (formData: FormRequestType): ThunkType => async (dispatch) => {
  await apiPostPhone(formData)
    .then(() => {
      dispatch(metrikSmsSended());
      dispatch(setIsStatusForm(FormStatus.Success));
    })
    .catch((error) => {
      if (error.response.data.captcha) {
        dispatch(setCaptcha(error.response.data.captcha));
      }
      if (error.response.data.error === 'Неверно указан код на картинке.') {
        dispatch(setIsErrorCaptcha(true));
      } else if (error.response.data.error !== 'Введите код на картинке.') {
        dispatch(setIsStatusForm(FormStatus.Error));
      }
    });
};

export const CSRFget = () => () => {
  apiCSRFget();
};

export const detectCityMiddleware = (): ThunkType => async (dispatch) => {
  await apiDetectCity().then((res: DetectCityType) => {
    dispatch(setDetectCity(res.city ? res.city : ''));
  });
};
