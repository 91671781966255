import { createSelector } from 'reselect';
import { InitialState } from '../rootTypes';
import {
  AppState,
  ArrayAnchors,
  MetaInfoType,
  ArrayBlocks,
  FormStatus,
  CaptchaType,
  CspHeadersType,
} from './types';

export const getApp = (state: InitialState): AppState => state.app;

export const getIsMobile = createSelector<InitialState, AppState, boolean>(
  getApp,
  (state) => state.isMobile
);

export const getIsLoading = createSelector<InitialState, AppState, boolean>(
  getApp,
  (state) => state.isLoading
);

export const getError = createSelector<InitialState, AppState, any>(getApp, (state) => state.error);

export const getAnchors = createSelector<InitialState, AppState, ArrayAnchors>(
  getApp,
  (state) => state.anchors
);

export const getMeta = createSelector<InitialState, AppState, MetaInfoType>(
  getApp,
  (state) => state.meta
);

export const getBlocks = createSelector<InitialState, AppState, ArrayBlocks>(
  getApp,
  (state) => state.blocks
);

export const getBlockById = (state: InitialState, id: string): any =>
  createSelector(getBlocks, (blocks) => {
    if (!blocks) {
      return null;
    }
    const activeBlock = blocks.find((item) => item.id === id);
    return activeBlock ? activeBlock.info : null;
  })(state);

export const getIsStatusForm = createSelector<InitialState, AppState, FormStatus>(
  getApp,
  (state) => state.isStatusForm
);

export const getCaptcha = createSelector<InitialState, AppState, CaptchaType>(
  getApp,
  (state) => state.captcha
);

export const getIsErrorCaptcha = createSelector<InitialState, AppState, boolean>(
  getApp,
  (state) => state.isErrorCaptcha
);

export const getCSPHeaders = createSelector<InitialState, AppState, CspHeadersType>(
  getApp,
  (state) => state.cspHeaders
);
