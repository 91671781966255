import React, { useRef } from 'react';
import { useVideoPlay } from 'hooks/useVideoPlay';
import { EnumViewType } from 'store/rootTypes';

import { PromoItemProps } from '../../Promo';
import styles from './Item.module.scss';

type PropsType = {
  endedVideoHandler: () => void;
  index: number;
  activeSlide: number;
};

type ComponentProps = PropsType & PromoItemProps;

const Item: React.FC<ComponentProps> = ({
  type,
  story_file,
  story_file_alt,
  story_text,
  index,
  activeSlide,
  endedVideoHandler,
}) => {
  const videoEL = useRef<HTMLVideoElement>(null);

  useVideoPlay(index, activeSlide, videoEL);

  return (
    <div className={styles.item}>
      {type === EnumViewType.Video ? (
        <video
          ref={videoEL}
          src={story_file}
          muted
          autoPlay
          playsInline
          onEnded={endedVideoHandler}
        />
      ) : (
        <img src={story_file} alt={story_file_alt} />
      )}
      {story_text && (
        <div className={styles.wrap} dangerouslySetInnerHTML={{ __html: story_text }} />
      )}
    </div>
  );
};
Item.displayName = 'PromoItem';

export default Item;
