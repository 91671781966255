import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { isBrowser, isMobile, isIPad13, isTablet } from 'react-device-detect';

import { useClickOutside } from '../../../hooks/useClickOutside';
import { ReactComponent as Plus } from '../../../assets/img/icon/plus.svg';
import { ReactComponent as Close } from '../../../assets/img/icon/close.svg';

import { ItemClasses, TariffsItemProps } from '../types';
import './Item.scss';

import TariffsItemInfo from './Info';
import TariffsItemSubinfo from './Subinfo';

type Props = { classes: ItemClasses };
type ComponentProps = Props & TariffsItemProps;

const Item: React.FC<ComponentProps> = ({
  classes,
  car_photo,
  tariff_name,
  car_names,
  title,
  details,
  car_photo_alt,
}) => {
  const [isOpenBlock, setIsOpenBlock] = useState<boolean>(false);
  const item = useRef<HTMLDivElement>(null);
  const [key, setKey] = useState<number>(0);

  const tablet = (): boolean => isTablet || isIPad13;
  const isNotBrowserOrTablet: boolean = !isBrowser || tablet();

  useEffect(() => {
    // fixed device detect for ipad, after change orientation
    setKey(key + 1);
  }, [isNotBrowserOrTablet]);

  useClickOutside(item, () => {
    if (isOpenBlock && isNotBrowserOrTablet) {
      closeBlock();
    }
  });

  useEffect(() => {
    if (isOpenBlock) {
      return;
    }
    if ((isBrowser && !isIPad13) || ((isMobile || isIPad13) && classes === ItemClasses.Center)) {
      setIsOpenBlock(true);
    }
  }, [isOpenBlock]);

  const toggleSlideActiveClass = (): void => {
    // eslint-disable-next-line no-unused-expressions
    item.current?.closest('.slick-slide')?.classList.toggle('open-block');
  };

  const openBlock = (): void => {
    toggleSlideActiveClass();
    setIsOpenBlock(true);
  };

  const closeBlock = (): void => {
    toggleSlideActiveClass();
    setIsOpenBlock(false);
  };

  return (
    <div
      key={key}
      ref={item}
      className={classnames(
        'tariffs-item',
        classes,
        { isTablet: isNotBrowserOrTablet },
        { active: isOpenBlock }
      )}
    >
      <div className="tariffs-item__wrap">
        {isNotBrowserOrTablet && !isOpenBlock && (
          <Plus className="tariffis-item__plus" onClick={openBlock} />
        )}
        <TariffsItemInfo
          car_photo_alt={car_photo_alt}
          car_photo={car_photo}
          tariff_name={tariff_name}
          car_names={car_names}
        />
        {isOpenBlock && (
          <div className={classnames('tariffs-item__block', { isTablet: isNotBrowserOrTablet })}>
            {isNotBrowserOrTablet && (
              <Close className="tariffis-item__close" fill="#979797" onClick={closeBlock} />
            )}
            <TariffsItemSubinfo title={title} details={details} />
          </div>
        )}
      </div>
    </div>
  );
};
Item.displayName = 'TariffsItem';

export default Item;
