import { connect } from 'react-redux';
import { InitialState, SectionIdEnum } from 'store/rootTypes';
import { getBlockById } from 'store/app';
import Drivers from './Drivers';

const mapStateToProps = (state: InitialState) => ({
  drivers: getBlockById(state, SectionIdEnum.Drivers),
});

const mapDispatchToProps = {};

export * from './types';
export default connect(mapStateToProps, mapDispatchToProps)(Drivers);
