import React from 'react';

import { SectionIdEnum } from 'store/rootTypes';
import UIBackground from '../ui/Background';
import styles from './Hero.module.scss';
import { ComponentProps } from './types';

const Hero: React.FC<ComponentProps> = ({ hero }) => {
  if (!hero) {
    return null;
  }
  return (
    <section className={styles.hero} id={SectionIdEnum.Startup}>
      <UIBackground
        video={hero.video}
        photo={hero.photo}
        poster={hero.poster}
        bgcolor={hero.bgcolor}
      />
      <div className={styles.logo}>
        <img src={hero.photo_svg} alt={hero.photo_svg_alt} />
      </div>
    </section>
  );
};

export default Hero;
