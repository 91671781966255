import React, { useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { useTimeoutNextSlide } from 'hooks/useTimeoutNextSlide';

import { EnumViewType } from 'store/rootTypes';
import { PromoItemProps, ComponentProps } from '../Promo';

import PromoItem from './Item';
import './Promo.scss';

const Promo: React.FC<ComponentProps> = ({ promo }) => {
  const slider = useRef<Slider>(null);
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const { timeoutNextSlide, nextSlide } = useTimeoutNextSlide(promo, slider);
  if (!promo) {
    return null;
  }

  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    customPaging() {
      return <button className="promo__dot" />;
    },
    beforeChange: (_, nextSlide) => {
      setActiveSlide(nextSlide);
      if (promo[nextSlide].type === EnumViewType.Image) {
        setTimeout(() => timeoutNextSlide(), 500);
      }
    },
  };

  return (
    <section className="promo" id="promo">
      <Slider ref={slider} className="promo__slider" {...settings}>
        {promo.map(
          (item: PromoItemProps, index: number): JSX.Element => (
            <PromoItem
              key={index}
              index={index}
              activeSlide={activeSlide}
              endedVideoHandler={nextSlide}
              {...item}
            />
          )
        )}
      </Slider>
    </section>
  );
};

export default Promo;
