import { connect, ConnectedProps } from 'react-redux';
import { getCities, getActiveCityName, getActiveCity, getDefaultCity } from 'store/cities';
import { InitialState } from 'store/rootTypes';
import { fetchMainInfo, getIsMobile, fetchMetaInfo } from 'store/app';

import { isOpenMenuPropsType } from '../Header';
import Cities from './Cities';

const mapStateToProps = (state: InitialState) => ({
  cities: getCities(state),
  activeName: getActiveCityName(state),
  active: getActiveCity(state),
  isMobile: getIsMobile(state),
  defaultCity: getDefaultCity(state),
});

const mapDispatchToProps = { fetchMainInfo, fetchMetaInfo };

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;
export type ComponentProps = PropsFromRedux & isOpenMenuPropsType & { isBlack: boolean };

export default connector(Cities);
