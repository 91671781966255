import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { isBrowser, isIPad13 } from 'react-device-detect';

import { CityItem } from 'store/cities/types';
import { ComponentProps } from '../Cities';
import styles from './Cities.module.scss';

const Cities: React.FC<ComponentProps> = ({
  cities,
  isOpenMenu,
  setIsOpenMenu,
  fetchMainInfo,
  activeName,
  active,
  isMobile,
  fetchMetaInfo,
  isBlack,
  defaultCity,
}) => {
  const [isOpenCities, setIsOpenCities] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = (): void => {
      if (isOpenMenu && window.scrollY > 150) {
        setIsOpenMenu(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isOpenMenu]);

  if (!cities) {
    return null;
  }

  const toggleMenu = (): void => {
    isMobile ? setIsOpenCities(!isOpenCities) : setIsOpenMenu(!isOpenMenu);
  };

  const citiesStyles: string = classnames(styles.cities, {
    [styles.openMenu]: isMobile ? isOpenCities : isOpenMenu,
    [styles.isBlack]: isBlack,
  });

  const getSlugUrl = (slug: string): string =>
    `/${slug === defaultCity ? '' : slug}${location.search}`;

  const changeCity = async (slug: string): Promise<void> => {
    await Promise.all([fetchMainInfo(slug), fetchMetaInfo(slug)]);
    history.push(getSlugUrl(slug));
    setIsOpenMenu(!isOpenMenu);
    if (isOpenCities && isMobile) {
      setIsOpenCities(!isOpenCities);
    }
  };

  const cityItem = (item: CityItem): JSX.Element | null => {
    if (item.slug === active && isMobile) {
      return null;
    }

    const itemClasses: string = classnames(styles.item, {
      [styles.activeCity]: item.slug === active,
    });

    return (
      <li key={item.slug} className={itemClasses} onClick={() => changeCity(item.slug)}>
        <a href={getSlugUrl(item.slug)} onClick={(e) => e.preventDefault()}>
          {item.name}
        </a>
      </li>
    );
  };

  const maxWidthPx = (): string => {
    const numberElInColumn: number = 4;
    const widthColumn: number = 170;
    const citiesListLength: number = cities.length;

    const maxWidth: number = Math.ceil(citiesListLength / numberElInColumn) * widthColumn;

    return isBrowser && !isIPad13 && cities.length > 0 ? `${maxWidth}px` : '100%';
  };

  return (
    <div className={citiesStyles}>
      <button type="button" className={styles.active} onClick={toggleMenu}>
        {activeName}
      </button>
      <div className={classnames(styles.block, { [styles.mobile]: isMobile })}>
        <div className={classnames(styles.container, 'container')}>
          <div className={styles.wrap}>
            <span className={styles.title}>Ваш город</span>
            <ul style={{ maxWidth: maxWidthPx() }} className={styles.list}>
              {cities.length !== 0 && cities.map(cityItem)}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cities;
