import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { isMobileOnly, isTablet, isIPad13, isIOS13 } from 'react-device-detect';

import { ReactComponent as YandexLogo } from '../../assets/img/global/yandex-go-logo.svg';
import Menu from './Menu';
import Cities from './Cities';
import styles from './Header.module.scss';

export type isOpenMenuPropsType = {
  isOpenMenu: boolean;
  setIsOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const Header: React.FC<{ isBlack?: boolean }> = ({ isBlack = false }) => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

  useEffect(() => {
    if (isMobileOnly || isIOS13) {
      document.body.style.overflow = isOpenMenu ? 'hidden' : '';
    }
  }, [isOpenMenu]);

  const toggleMenu = (): void => setIsOpenMenu(!isOpenMenu);

  const isOpenMenuProps: isOpenMenuPropsType = {
    isOpenMenu,
    setIsOpenMenu,
  };

  const headerMainStyles: string = classnames(styles.header, {
    [styles.openMenu]: isOpenMenu,
    [styles.isTablet]: isTablet || isIPad13,
    [styles.isBlack]: isBlack,
  });

  return (
    <header className={headerMainStyles}>
      <div className={styles.container + ' container'}>
        <div className={styles.wrap}>
          <span className={styles.burger} onClick={toggleMenu} />
          <div className={styles.logo}>
            <YandexLogo />
          </div>
          <div className={styles.menu}>
            <Menu {...isOpenMenuProps} isBlack={isBlack} />
          </div>
          <div className={styles.cities}>
            <Cities {...isOpenMenuProps} isBlack={isBlack} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
