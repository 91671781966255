import React from 'react';
import classnames from 'classnames';

import styles from './InfoItem.module.scss';

type FeaturesInfoItemProps = {
  title: string;
  description: string;
};

const InfoItem: React.FC<FeaturesInfoItemProps> = ({ title, description }) => {
  return (
    <div className={styles.item}>
      <h4 className={classnames(styles.title, 'section-title')}>{title}</h4>
      <p className={classnames(styles.text, 'text')}>{description}</p>
    </div>
  );
};
InfoItem.displayName = 'FeaturesInfoItem';

export default InfoItem;
