import React from 'react';

import { FormStatus, FormRequestType } from 'store/app';
import { useTimer } from 'hooks/useTimer';
import { ReactComponent as Success } from '../../../assets/img/icon/success.svg';
import { ReactComponent as Close } from '../../../assets/img/icon/close.svg';
import { ComponentProps } from '../Form';
import ComponentFormView from './View';
import ComponentFormStatus from './Status';

const DEFAULT_TIMER_SECONDS = 30;

const Form: React.FC<ComponentProps> = ({ isStatusForm, setIsStatusForm, postPhone, ...props }) => {
  const { timeLeft, setTimeLeft } = useTimer(DEFAULT_TIMER_SECONDS);

  const successButtonText: string = `Отправить еще раз${
    timeLeft > 0 ? ` через ${timeLeft} секунд` : ''
  }`;

  const reloadHandler = (): void => {
    if (timeLeft !== 0) {
      return;
    }
    setIsStatusForm(FormStatus.Init);
  };

  const tryHandler = (): void => {
    setIsStatusForm(FormStatus.Init);
    setTimeLeft(0);
  };

  const postPhoneHandler = async (data: FormRequestType) => {
    await postPhone(data);
    setTimeLeft(DEFAULT_TIMER_SECONDS);
  };

  if (isStatusForm === FormStatus.Success) {
    return (
      <ComponentFormStatus
        title="Вам отправлено смс!"
        btn={successButtonText}
        onClick={reloadHandler}
      >
        <Success />
      </ComponentFormStatus>
    );
  }

  if (isStatusForm === FormStatus.Error) {
    return (
      <ComponentFormStatus
        title="Извините, произошла ошибка"
        btn="Попробовать ещё раз"
        onClick={tryHandler}
      >
        <Close stroke="#656A71" fill="#656A71" />
      </ComponentFormStatus>
    );
  }

  return (
    <ComponentFormView
      setIsStatusForm={setIsStatusForm}
      isStatusForm={isStatusForm}
      onSubmit={postPhoneHandler}
      {...props}
    />
  );
};

Form.displayName = 'SendingForm';

export default Form;
