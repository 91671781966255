import { connect, ConnectedProps } from 'react-redux';
import { InitialState, SectionIdEnum } from 'store/rootTypes';
import { fetchMainInfo, getBlockById, getBlocks } from 'store/app';
import { scrollPrivilegesSection } from './../../store/metriks/middleware';
import CityPage from './CityPage';

const mapStateToProps = (state: InitialState) => ({
  blocks: getBlocks(state),
  privileges: getBlockById(state, SectionIdEnum.Privileges),
});
const mapDispatchToProps = { fetchMainInfo, scrollPrivilegesSection };

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;
export type ComponentProps = PropsFromRedux;

export default connector(CityPage);
