import React, { useState, useRef } from 'react';
import classnames from 'classnames';

import { useClickOutside } from '../../../hooks/useClickOutside';
import { ReactComponent as Close } from '../../../assets/img/icon/close.svg';
import './Item.scss';
import { PrivilegeItemProps } from '../types';

const Item: React.FC<PrivilegeItemProps> = ({
  photo,
  title,
  short_description,
  description,
  details_link,
  photo_alt,
  image_link,
}) => {
  const [isOpenMore, setIsOpenMore] = useState<boolean>(false);
  const item = useRef<HTMLDivElement>(null);

  useClickOutside(item, () => {
    if (isOpenMore) {
      toggleMore();
    }
  });

  const moreBlockClasses: string = classnames('privilege-item__more', { open: isOpenMore });

  const toggleMore = (): void => {
    // eslint-disable-next-line no-unused-expressions
    item.current
      ?.closest('.slick-slider')
      ?.querySelector('.slick-dots')
      ?.classList.toggle('open-more');
    setIsOpenMore(!isOpenMore);
  };

  const TitleBlock = (): JSX.Element => <span className="privilege-item__title">{title}</span>;
  const ImageBlock = (): JSX.Element => {
    const Tag = image_link ? 'a' : 'div';
    const props: { href?: string; target?: string } = {};
    if (image_link) {
      props.href = image_link;
      props.target = '_blank';
    }
    return (
      <Tag className="privilege-item__img" {...props}>
        <img src={photo} alt={photo_alt} />
      </Tag>
    );
  };

  return (
    <div className="privilege-item" ref={item}>
      <TitleBlock />
      <ImageBlock />
      <div className="privilege-item__info">
        <TitleBlock />
        <p className={classnames('privilege-item__text', 'text')}>{short_description}</p>
        <button className="privilege-item__btn" type="button" onClick={toggleMore}>
          Подробнее
        </button>
        <div className={moreBlockClasses}>
          <button className="privilege-item__more-close" type="button" onClick={toggleMore}>
            <Close fill="#979797" />
          </button>
          <p className={classnames('privilege-item__more-text', 'text')}>
            {description}{' '}
            <a href={details_link} target="_blank" rel="noopener noreferrer">
              {details_link}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
Item.displayName = 'PrivilegeItem';

export default Item;
