import { connect } from 'react-redux';
import { InitialState, SectionIdEnum } from 'store/rootTypes';
import { getBlockById, postPhone } from 'store/app';
import {
  clickAndroidLink,
  clickDownloadLink,
  clickIosLink,
} from './../../store/metriks/middleware';
import Sending from './Sending';

const mapStateToProps = (state: InitialState) => ({
  download: getBlockById(state, SectionIdEnum.Download),
});

const mapDispatchToProps = { postPhone, clickAndroidLink, clickDownloadLink, clickIosLink };
export * from './types';
export default connect(mapStateToProps, mapDispatchToProps)(Sending);
