import React from 'react';
import classnames from 'classnames';
import Slider, { Settings } from 'react-slick';

import PrivilegeItem from './Item';
import './Privilege.scss';
import { ComponentProps, PrivilegeItemProps } from './types';

const Privilege: React.FC<ComponentProps> = ({ privileges }) => {
  if (!privileges) {
    return null;
  }
  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    customPaging(index) {
      return <button className="slick-dot" aria-label={`slider dot ${index + 1}`} />;
    },
  };

  return (
    <section className="privilege" id="privileges">
      <h2 className={classnames('privilege__title', 'section-title')}>{privileges.title}</h2>
      <Slider
        className={classnames('privilege__slider', {
          'with-dots': privileges.privileges.length > 1,
        })}
        {...settings}
      >
        {privileges.privileges.map(
          (item: PrivilegeItemProps, index: number): JSX.Element => (
            <PrivilegeItem key={index} {...item} />
          )
        )}
      </Slider>
    </section>
  );
};

export default Privilege;
