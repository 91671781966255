import React from 'react';

import { TariffsFeaturesItem } from '../types';

type TariffsItemSubinfoProps = {
  title: string;
  details: Array<TariffsFeaturesItem>;
};

const Subinfo: React.FC<TariffsItemSubinfoProps> = ({ details, title }) => {
  return (
    <div className="tariffs-item__subwrap">
      <h5 className="tariffs-item__subtitle">{title}</h5>
      <ul className="tariffs-item__features">
        {details.map((item: TariffsFeaturesItem, index: number) => (
          <li key={index}>
            <img src={item.icon} alt={item.icon_alt} />
            {item.description}
          </li>
        ))}
      </ul>
    </div>
  );
};
Subinfo.displayName = 'TariffsItemSubinfo';

export default Subinfo;
