import React from 'react';
import classnames from 'classnames';
import styles from './Button.module.scss';

type TagsType = 'a' | 'button' | 'input';

type ButtonPropsType = {
  type?: 'button' | 'submit' | 'reset';
  children?: React.ReactChild;
  onClick?: (event: React.MouseEvent) => void;
  disabled?: boolean;
  tag?: TagsType;
  otherClass?: string;
  href?: string;
  target?: '_blank';
  value?: string;
};

const Button: React.FC<ButtonPropsType> = ({
  type = 'button',
  tag = 'button',
  disabled = false,
  children,
  otherClass,
  ...props
}) => {
  const Tag: TagsType = tag;

  const tagProps = {
    className: classnames(styles.btn, otherClass),
    type,
    disabled,
    ...props,
  };

  if (tag === 'input') {
    return <input {...tagProps} />;
  }

  return (
    <Tag {...tagProps}>
      <span>{children}</span>
    </Tag>
  );
};

Button.displayName = 'UIButton';

export default Button;
