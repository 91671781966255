import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import { FormStatus, FormRequestType } from 'store/app';
import UIButton from '../../../ui/Button';

import styles from './View.module.scss';

type ComponentProps = {
  qrCode: string;
  qrCodeNote: string;
};

const View: React.FC<ComponentProps> = ({ qrCode, qrCodeNote }) => {
  if (!qrCode) {
    return null;
  }
  return (
    <div className={styles.form}>
      <div className={classnames(styles.row, styles.captcha)}>
        <div className={classnames(styles.field, styles.qr_code)}>
          <img src={qrCode} alt="QR-код" title="QR-код" width="100" height="100" />
        </div>
      </div>
      {qrCodeNote && (
        <div className={classnames(styles.text, 'text')}>
          <br />
          {qrCodeNote}
        </div>
      )}
    </div>
  );
};

View.displayName = 'SendingFormView';

export default View;
