import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import classnames from 'classnames';

import Links from './Links';
import SendingForm from './Form';
import styles from './Sending.module.scss';
import { ComponentProps } from './types';

const Sending: React.FC<ComponentProps> = ({
  download,
  clickAndroidLink,
  clickDownloadLink,
  clickIosLink,
}) => {
  if (!download) {
    return null;
  }
  return (
    <section className={styles.sending} id="download">
      <h2 className={classnames(styles.title, 'section-title')}>{download.title}</h2>
      <p className={classnames(styles.text, 'text')}>{download.short_description}</p>
      {!isMobileOnly && (
        <SendingForm qrCode={download.qr_code} qrCodeNote={download.qr_code_note} />
      )}
      {isMobileOnly && (
        <div className={styles.links}>
          <Links
            appleStoreLink={download.app_store_link}
            googlePlayLink={download.google_play_link}
            clickAndroidLink={clickAndroidLink}
            clickDownloadLink={clickDownloadLink}
            clickIosLink={clickIosLink}
          />
        </div>
      )}
    </section>
  );
};

export default Sending;
