import React from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import UIButton from '../../components/ui/Button';

import './NotFound.scss';

const NotFound: React.FC<any> = () => {
  const history = useHistory();
  const buttonClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    history.push('/');
  };
  return (
    <div className="not-found-page">
      <Header isBlack />
      <main className="main">
        <div className="not-found-page__wrap">
          <h1 className="not-found-page__title section-title">страница не найдена</h1>
          <UIButton tag="a" href="/" onClick={buttonClickHandler}>
            Вернуться на главную
          </UIButton>
        </div>
      </main>
    </div>
  );
};

export default NotFound;
