import { connect } from 'react-redux';
import { InitialState, SectionIdEnum } from 'store/rootTypes';
import { getBlockById } from 'store/app';
import Privilege from './Privilege';

const mapStateToProps = (state: InitialState) => ({
  privileges: getBlockById(state, SectionIdEnum.Privileges),
});

const mapDispatchToProps = {};

export * from './types';

export default connect(mapStateToProps, mapDispatchToProps)(Privilege);
