import { connect } from 'react-redux';
import { InitialState, SectionIdEnum } from 'store/rootTypes';
import { getBlockById } from 'store/app';
import Footer from './Footer';

const mapStateToProps = (state: InitialState) => ({
  disclaimer: getBlockById(state, SectionIdEnum.Disclaimer),
});

const mapDispatchToProps = {};

export * from './types';
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
