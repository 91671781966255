import React from 'react';
import { isMobileOnly } from 'react-device-detect';

type TariffsItemInfoProps = {
  car_photo: string;
  car_photo_alt: string;
  tariff_name: string;
  car_names: Array<string>;
};

const Info: React.FC<TariffsItemInfoProps> = ({
  car_photo,
  car_photo_alt,
  tariff_name,
  car_names,
}) => {
  return (
    <div className="tariffs-item__info">
      <div className="tariffs-item__img">
        <img src={car_photo} alt={car_photo_alt} />
      </div>
      <h4 className="tariffs-item__name">{tariff_name}</h4>
      <ul className="tariffs-item__cars">
        {car_names.map((item: string, index: number) => (
          <li key={index}>
            {item}
            {isMobileOnly && index !== car_names.length - 1 && ','}
          </li>
        ))}
      </ul>
    </div>
  );
};
Info.displayName = 'TariffsItemInfo';

export default Info;
