import { produce } from 'immer';
import {
  ActionConst,
  ActionTypes,
  AppState,
  ArrayAnchors,
  ArrayBlocks,
  MetaInfoType,
  FormStatus,
  CaptchaType,
  CspHeadersType,
} from '../app';

export const initialState = {
  isMobile: false,
  isLoading: false,
  error: {} as any,
  anchors: [] as ArrayAnchors,
  blocks: [] as ArrayBlocks,
  meta: {} as MetaInfoType,
  cspHeaders: {
    'content-security-policy': '',
    'content-security-policy-report-only': '',
  } as CspHeadersType,
  isStatusForm: 'init' as FormStatus,
  captcha: {} as CaptchaType,
  isErrorCaptcha: false,
};

export default (state: AppState = initialState, action: ActionTypes): AppState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case ActionConst.SET_ISMOBILE:
        draftState.isMobile = action.payload;
        break;
      case ActionConst.FETCH_REQUEST:
        draftState.isLoading = true;
        break;
      case ActionConst.FETCH_SUCCESS:
        draftState.isLoading = false;
        break;
      case ActionConst.FETCH_FAILURE:
        draftState.isLoading = false;
        draftState.error = action.payload;
        break;
      case ActionConst.SET_ANCHORS:
        draftState.anchors = action.payload;
        break;
      case ActionConst.SET_BLOCKS:
        draftState.blocks = action.payload;
        break;
      case ActionConst.SET_META:
        draftState.meta = action.payload;
        break;
      case ActionConst.SET_IS_STATUS_FORM:
        draftState.isStatusForm = action.payload;
        break;
      case ActionConst.SET_CAPTCHA:
        draftState.captcha = action.payload;
        break;
      case ActionConst.SET_IS_ERROR_CAPTCHA:
        draftState.isErrorCaptcha = action.payload;
        break;
      case ActionConst.SET_CSP_HEADERS:
        draftState.cspHeaders = {
          'content-security-policy': action.payload['content-security-policy'],
          'content-security-policy-report-only':
            action.payload['content-security-policy-report-only'],
        };
        break;
    }
  });
};
