import { produce } from 'immer';
import { ArrayCities, CityState, ActionTypes } from './types';
import { ActionConst } from './actions';

export const initialState = {
  cities: [] as ArrayCities,
  active: '',
  defaultCity: '',
  detectCity: '',
};

export default (state: CityState = initialState, action: ActionTypes): CityState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case ActionConst.SET_CITIES:
        draftState.cities = action.payload;
        break;
      case ActionConst.SET_ACTIVE_CITY:
        draftState.active = action.payload;
        break;
      case ActionConst.SET_DEFAULT_CITY:
        draftState.defaultCity = action.payload;
        break;
      case ActionConst.SET_DETECT_CITY:
        draftState.detectCity = action.payload;
        break;
    }
  });
};
