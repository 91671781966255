import React, { useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { useTimeoutNextSlide } from 'hooks/useTimeoutNextSlide';
import { FeaturesItemType, ComponentProps } from './types';
import FeaturesInfoItem from './InfoItem';
import FeaturesViewItem from './ViewItem';
import './Features.scss';

const Features: React.FC<ComponentProps> = ({ features }) => {
  if (!features) {
    return null;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const slider1 = useRef<Slider>(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const slider2 = useRef<Slider>(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [activeSlide, setActiveSlide] = useState<number>(0);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { nextSlide } = useTimeoutNextSlide(features.bullets, slider1);

  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
  };

  const infoSettings: Settings = {
    ...sliderSettings,
    dots: true,
    speed: 500,
    customPaging(index) {
      return <button className="slick-dot" aria-label={`slider dot ${index + 1}`} />;
    },
    adaptiveHeight: true,
    beforeChange: (_, nextSlide) => {
      // eslint-disable-next-line no-unused-expressions
      slider2.current?.slickGoTo(nextSlide);
      setActiveSlide(nextSlide);
    },
  };

  const viewSettings: Settings = {
    ...sliderSettings,
    dots: false,
    speed: 0,
    touchMove: false,
  };

  return (
    <section className="features" id="features">
      <div className="features__wrap">
        <div className="features__info">
          <Slider ref={slider1} className="features__slider" {...infoSettings}>
            {features.bullets.map(
              (item: FeaturesItemType, index: number): JSX.Element => (
                <FeaturesInfoItem key={index} title={item.title} description={item.description} />
              )
            )}
          </Slider>
        </div>
        <div className="features__view">
          <Slider ref={slider2} className="features__slider" {...viewSettings}>
            {features.bullets.map(
              (item: FeaturesItemType, index: number): JSX.Element => (
                <FeaturesViewItem
                  key={index}
                  index={index}
                  activeSlide={activeSlide}
                  endedVideoHandler={nextSlide}
                  video={item.video}
                />
              )
            )}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Features;
