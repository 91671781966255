import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { SectionIdEnum } from 'store/rootTypes';

import styles from './Footer.module.scss';
import { ComponentProps } from './types';

const Footer: React.FC<ComponentProps> = ({ disclaimer }) => {
  if (!disclaimer) {
    return null;
  }

  return (
    <footer className={styles.footer} id={SectionIdEnum.Disclaimer}>
      {!isMobileOnly && disclaimer.content && (
        <div
          className={styles.copyright}
          dangerouslySetInnerHTML={{ __html: disclaimer.content }}
        />
      )}
      <div
        className={styles.copyright}
        dangerouslySetInnerHTML={{ __html: disclaimer.short_description }}
      />
    </footer>
  );
};

export default Footer;
