import { ThunkAction } from 'redux-thunk';

// blocks type
import { StartupType } from 'components/Hero';
import { ServiceType } from 'components/About';
import { ArrayPromoType } from 'components/Promo';
import { DriversType } from 'components/Drivers';
import { DisclaimerType } from 'components/Footer';
import { FeaturesType } from 'components/Features';
import { PrivilegesType } from 'components/Privilege';
import { ArrayTariffs } from 'components/Tariffs';
import { DownloadType } from 'components/Sending';

import { ActionTypes as CitiesActionTypes, ArrayCities } from '../cities';
import { InferValueTypes, InitialState, SectionIdEnum } from '../rootTypes';
import { initialState } from './reducer';
import { actions } from './actions';

export const errorNotExistMessage = 'not exist city';

export type AnchorItem = {
  name: string;
  id: string;
};

export type ArrayAnchors = Array<AnchorItem>;

export type AppState = typeof initialState;
export type ActionTypes = ReturnType<InferValueTypes<typeof actions>> | CitiesActionTypes;
export type ThunkType = ThunkAction<void, InitialState, unknown, ActionTypes>;

export type BlockInfoProps =
  | StartupType
  | ServiceType
  | ArrayPromoType
  | DriversType
  | DisclaimerType
  | ArrayTariffs
  | FeaturesType
  | PrivilegesType
  | DownloadType;

export type BlockItemProps = {
  id: SectionIdEnum;
  info: BlockInfoProps;
};

export type ArrayBlocks = Array<BlockItemProps>;

export type FetchMainInfoResponseType = {
  cities: ArrayCities;
  anchors: ArrayAnchors;
  blocks: ArrayBlocks;
  default_city: string;
  exist_city: boolean;
};

export type MetaInfoType = {
  'ya_metrika_id': number;
  'ya_metrika_clickmap': boolean;
  'ya_metrika_track_links': boolean;
  'ya_metrika_accurate_track_bounce': boolean;
  'ya_metrika_webvisor': boolean;
  'yandex-verification': string;
  'google-site-verification': string;
  'meta_title': string;
  'meta_description': string;
  'meta_og_title': string;
  'meta_og_url': string;
  'meta_og_description': string;
  'meta_og_type': string;
  'meta_og_site_name': string;
  'meta_fb_app_id': string;
  'meta_mrc__share_title': string;
  'meta_mrc__share_description': string;
  'meta_og_image': string;
  'csp_nonce': string;
};

export type apiMainInfoQueryType = {
  mode: 'preview';
  story: string;
};

export enum FormStatus {
  Loading = 'loading',
  Init = 'init',
  Submit = 'submit',
  Success = 'success',
  Error = 'error',
}

export type FormRequestType = {
  phone: string;
  captcha?: string;
  captcha_key?: string;
};

export type CaptchaType = {
  image_url: string;
  key: string;
};

export type DetectCityType = {
  city?: string;
};

export type CspHeadersType = {
  'content-security-policy'?: string;
  'content-security-policy-report-only'?: string;
};
