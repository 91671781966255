import React from 'react';
import classnames from 'classnames';

import { SectionIdEnum } from 'store/rootTypes';
import styles from './About.module.scss';
import { ComponentProps } from './types';

const About: React.FC<ComponentProps> = ({ about }) => {
  if (!about) {
    return null;
  }

  return (
    <section className={styles.about} id={SectionIdEnum.Service}>
      <h2 className={classnames(styles.title, 'section-title')}>{about.title}</h2>
      <p className={classnames(styles.text, 'text')}>{about.content}</p>
    </section>
  );
};

export default About;
