import {
  ArrayAnchors,
  ArrayBlocks,
  MetaInfoType,
  FormStatus,
  CaptchaType,
  CspHeadersType,
} from './types';

export const ActionConst = {
  FETCH_REQUEST: '@@/app/FETCH_REQUEST' as const,
  FETCH_SUCCESS: '@@/app/FETCH_SUCCESS' as const,
  FETCH_FAILURE: '@@/app/FETCH_FAILURE' as const,
  SET_ISMOBILE: '@@/app/SET_ISMOBILE' as const,
  SET_ANCHORS: '@@/app/SET_ANCHORS' as const,
  SET_BLOCKS: '@@/app/SET_BLOCKS' as const,
  SET_META: '@@/app/SET_META' as const,
  SET_IS_STATUS_FORM: '@@/app/SET_IS_STATUS_FORM' as const,
  SET_CAPTCHA: '@@/app/SET_CAPTCHA' as const,
  SET_IS_ERROR_CAPTCHA: '@@/app/SET_IS_ERROR_CAPTCHA' as const,
  SET_CSP_HEADERS: '@@/app/SET_CSP_HEADERS' as const,
};

export const fetchRequest = () => ({ type: ActionConst.FETCH_REQUEST });

export const fetchSuccess = () => ({ type: ActionConst.FETCH_SUCCESS });

export const fetchFailure = (error: any) => ({ type: ActionConst.FETCH_FAILURE, payload: error });

export const setAnchors = (anchors: ArrayAnchors) => ({
  type: ActionConst.SET_ANCHORS,
  payload: anchors,
});

export const setBlocks = (blocks: ArrayBlocks) => ({
  type: ActionConst.SET_BLOCKS,
  payload: blocks,
});

export const setMeta = (meta: MetaInfoType) => ({
  type: ActionConst.SET_META,
  payload: meta,
});

export const setIsMobile = (status: boolean) => ({
  type: ActionConst.SET_ISMOBILE,
  payload: status,
});

export const setIsStatusForm = (status: FormStatus) => ({
  type: ActionConst.SET_IS_STATUS_FORM,
  payload: status,
});

export const setCaptcha = (captcha: CaptchaType) => ({
  type: ActionConst.SET_CAPTCHA,
  payload: captcha,
});

export const setIsErrorCaptcha = (status: boolean) => ({
  type: ActionConst.SET_IS_ERROR_CAPTCHA,
  payload: status,
});

export const setСspHeaders = (cspHeaders: CspHeadersType) => ({
  type: ActionConst.SET_CSP_HEADERS,
  payload: cspHeaders,
});

export const actions = {
  setIsMobile,
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  setAnchors,
  setBlocks,
  setMeta,
  setIsStatusForm,
  setCaptcha,
  setIsErrorCaptcha,
  setСspHeaders,
};
