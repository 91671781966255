import { connect } from 'react-redux';
import { InitialState, SectionIdEnum } from 'store/rootTypes';
import { getBlockById } from 'store/app';
import About from './About';

const mapStateToProps = (state: InitialState) => ({
  about: getBlockById(state, SectionIdEnum.Service),
});

const mapDispatchToProps = {};

export * from './types';
export default connect(mapStateToProps, mapDispatchToProps)(About);
