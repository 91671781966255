import { useEffect, useState } from 'react';

type ReturnHooks = {
  timeLeft: number;
  setTimeLeft: (seconds: number) => void;
};

export function useTimer(seconds: number): ReturnHooks {
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    if (!timeLeft) {
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return { timeLeft, setTimeLeft };
}
