import React from 'react';
import classnames from 'classnames';
import Scroll, { Link } from 'react-scroll';
import { useLocation, useHistory } from 'react-router-dom';

import { AnchorItem } from 'store/app';
import { ComponentProps } from '../Menu';
import styles from './Menu.module.scss';

const Menu: React.FC<ComponentProps> = ({ isOpenMenu, setIsOpenMenu, anchors, isBlack }) => {
  const location = useLocation();
  const history = useHistory();
  if (!anchors) {
    return null;
  }
  const menuListStyles: string = classnames(styles.list, {
    [styles.openMenu]: isOpenMenu,
    [styles.isBlack]: isBlack,
  });

  const clickMenu = (item: AnchorItem): void => {
    const scroller = Scroll.scroller;
    if (location.pathname === '/not-found') {
      history.push('/');
      setTimeout(() => {
        scroller.scrollTo(item.id, { smooth: true });
      }, 0);
    }
    setIsOpenMenu(false);
  };

  const menuItem = (item: AnchorItem): JSX.Element => (
    <Link
      href="#"
      onClick={() => clickMenu(item)}
      to={item.id}
      className={styles.link}
      key={item.id}
      smooth={true}
    >
      {item.name}
    </Link>
  );

  return <nav className={menuListStyles}>{anchors.map(menuItem)}</nav>;
};

export default Menu;
