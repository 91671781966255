import { connect, ConnectedProps } from 'react-redux';
import { InitialState } from 'store/rootTypes';
import {
  postPhone,
  getIsStatusForm,
  setIsStatusForm,
  getCaptcha,
  getIsErrorCaptcha,
  setIsErrorCaptcha,
} from 'store/app';
import Form from './Form';

const mapStateToProps = (state: InitialState) => ({
  isStatusForm: getIsStatusForm(state),
  captcha: getCaptcha(state),
  isErrorCaptcha: getIsErrorCaptcha(state),
});
const mapDispatchToProps = { postPhone, setIsStatusForm, setIsErrorCaptcha };

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;
export type ComponentProps = PropsFromRedux;

export default connector(Form);
