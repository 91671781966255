import { AxiosResponse } from 'axios';
import { axiosGet, axiosPost } from '../../utils/api';
import { apiMainInfoQueryType, FormRequestType } from './types';

export const apiMainInfo = async (slug?: string, query?: apiMainInfoQueryType, cookie?: string) => {
  const payload: Array<string> = [];
  if (slug) {
    payload.push(`city=${slug}`);
  }
  if (query) {
    Object.keys(query).map((key) => {
      payload.push(`${key}=${query[key as keyof apiMainInfoQueryType]}`);
    });
  }
  const url: string = `v1/main/info${payload.length > 0 ? `?${payload.join('&')}` : ''}`;
  try {
    const res: AxiosResponse = await axiosGet(url, cookie);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const apiMetaInfo = async (slug?: string) => {
  try {
    const res: AxiosResponse = await axiosGet(`v1/meta/info${slug ? `?city=${slug}` : ''}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiCSRFget = async () => {
  try {
    const res: AxiosResponse = await axiosGet('v1/csrf');
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiPostPhone = async (formData: FormRequestType) => {
  const data = new FormData();
  data.set('phone', formData.phone);
  if (formData.captcha && formData.captcha_key) {
    data.set('captcha', formData.captcha);
    data.set('captcha_key', formData.captcha_key);
  }
  try {
    await axiosPost('v1/app-link', data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiDetectCity = async () => {
  try {
    const res: AxiosResponse = await axiosGet('v1/detect/city');
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
