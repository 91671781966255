export enum ItemClasses {
  Rtl = 'rtl',
  Ltr = 'ltr',
  Area = 'area',
  Center = 'center',
  Inner = 'inner',
}

export type TariffsFeaturesItem = {
  icon: string;
  icon_alt: string;
  description: string;
};

export type TariffsItemProps = {
  car_photo: string;
  car_photo_alt: string;
  title: string;
  car_names: Array<string>;
  tariff_name: string;
  details: Array<TariffsFeaturesItem>;
};

export type ArrayTariffs = Array<TariffsItemProps>;

export type ComponentProps = {
  tariffs: ArrayTariffs;
  isMobile: boolean;
};
