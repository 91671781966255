export const getCookie = (name: string): string => {
  let cookieValue: string = '';
  if (document.cookie && document.cookie !== '') {
    const cookies: Array<string> = document.cookie.split(';');
    cookies.forEach((item) => {
      const cookie: string = item.trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=' && !cookieValue) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
      }
    });
  }
  return cookieValue;
};
