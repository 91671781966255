import { createSelector } from 'reselect';
import { InitialState } from '../rootTypes';
import { CityState, ArrayCities } from './types';

export const getCitiesModule = (state: InitialState): CityState => state.cities;

export const getCities = createSelector<InitialState, CityState, ArrayCities>(
  getCitiesModule,
  (data) => data.cities
);

export const getActiveCity = createSelector<InitialState, CityState, string>(
  getCitiesModule,
  (data) => data.active
);

export const getDefaultCity = createSelector<InitialState, CityState, string>(
  getCitiesModule,
  (data) => data.defaultCity
);

export const getDetectCity = createSelector<InitialState, CityState, string>(
  getCitiesModule,
  (data) => data.detectCity
);

export const getActiveCityName = createSelector<InitialState, CityState, string, string>(
  [getCitiesModule, getActiveCity],
  (data, active) => {
    if (!data.cities) {
      return '';
    }
    const activeItem = data.cities.find((item) => item.slug === active);
    return activeItem ? activeItem.name : '';
  }
);
