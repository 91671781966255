import React, { useEffect } from 'react';
import { YMInitializer } from 'react-yandex-metrika';
import { Helmet } from 'react-helmet-async';
import { Route, Switch, Redirect } from 'react-router-dom';

import CityPage from '../pages/CityPage';
import NotFoundPage from '../pages/NotFound';
import routes from '../routes';
import './App.scss';
import { ComponentProps } from '../App';

const App: React.FC<ComponentProps> = ({ meta, fetchMetaInfo, activeCity, CSRFget }) => {
  useEffect(() => {
    if (!meta) {
      fetchMetaInfo(activeCity);
    }
    CSRFget();
  }, []);

  return (
    <>
      <Helmet title={meta.meta_title}>
        <link rel="shortcut icon" href={meta.meta_favicon} type="image/x-icon" />
        <meta name="description" content={meta.meta_description} />
        <meta property="og:title" content={meta.meta_og_title} />
        <meta property="og:url" content={meta.meta_og_url} />
        <meta property="og:type" content={meta.meta_og_type} />
        <meta property="og:image" content={meta.meta_og_image} />
        <meta property="og:description" content={meta.meta_og_description} />
        <meta property="og:site_name" content={meta.meta_og_site_name} />
        <meta property="fb:app_id" content={meta.meta_fb_app_id} />
        <meta property="mrc__share_title" content={meta.meta_mrc__share_title} />
        <meta property="mrc__share_description" content={meta.meta_mrc__share_description} />
        <meta name="yandex-verification" content={meta['yandex-verification']} />
        <meta name="google-site-verification" content={meta['google-site-verification']} />
      </Helmet>
      <YMInitializer
        accounts={[meta.ya_metrika_id]}
        options={{
          clickmap: meta.ya_metrika_clickmap,
          trackLinks: meta.ya_metrika_track_links,
          accurateTrackBounce: meta.ya_metrika_accurate_track_bounce,
          webvisor: meta.ya_metrika_webvisor,
        }}
        version="2"
      />
      <Switch>
        <Redirect strict from={routes.city + '/'} to={routes.city} />
        <Route exact path="/" component={CityPage} />
        <Route exact path={routes.notFound} component={NotFoundPage} />
        <Route sensitive path={routes.city} component={CityPage} />
        <Route render={() => '404!'} />
      </Switch>
    </>
  );
};

export default App;
