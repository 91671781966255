import { connect, ConnectedProps } from 'react-redux';
import { InitialState } from 'store/rootTypes';
import { getAnchors } from 'store/app';
import { isOpenMenuPropsType } from '../Header';
import Menu from './Menu';

const mapStateToProps = (state: InitialState) => ({
  anchors: getAnchors(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;
export type ComponentProps = PropsFromRedux & isOpenMenuPropsType & { isBlack: boolean };

export default connector(Menu);
