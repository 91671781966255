import React from 'react';
import classnames from 'classnames';

import styles from './Status.module.scss';

type StatusProps = {
  title: string;
  btn: string;
  onClick: () => void;
  children: React.ReactChild;
};

const Status: React.FC<StatusProps> = ({ children, title, btn, onClick }) => {
  return (
    <div className={styles.status}>
      <div className={styles.icon}>{children}</div>
      <span className={styles.title}>{title}</span>
      <button className={classnames(styles.reload, 'text')} type="button" onClick={onClick}>
        {btn}
      </button>
    </div>
  );
};
Status.displayName = 'SendingFormStatus';

export default Status;
