import React, { useEffect, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import classNames from 'classnames';

import { BackgroundColor } from 'chalk';
import styles from './Background.module.scss';

type ComponentProps = {
  video: string;
  photo?: string;
  poster?: string;
  bgcolor?: string;
};

const Background: React.FC<ComponentProps> = ({ video, photo, poster, bgcolor }) => {
  const [key, setKey] = useState<number>(0);

  useEffect(() => {
    // fixed device detect for ipad, after change orientation
    setKey(key + 1);
  }, [isBrowser]);

  if (!photo && !video) {
    return null;
  }

  return (
    <div className={styles.bg} key={key} style={{ backgroundColor: bgcolor }}>
      {isBrowser && (
        <video
          className={styles.bgVideo}
          src={video}
          muted
          autoPlay
          loop
          playsInline
          poster={poster}
        />
      )}
      {photo && (
        <div
          className={classNames(styles.bgImage, { [styles.active]: video && isBrowser })}
          style={{ backgroundImage: `url(${photo})` }}
        />
      )}
    </div>
  );
};

Background.displayName = 'UIBackground';

export default Background;
