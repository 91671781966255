import React, { useRef, useState } from 'react';
import { isBrowser, isIPad13 } from 'react-device-detect';
import Slider, { Settings } from 'react-slick';
import classnames from 'classnames';

import TariffsItem from './Item';
import './Tariffs.scss';
import { ComponentProps, TariffsItemProps, ItemClasses } from './types';

const Tariffs: React.FC<ComponentProps> = ({ tariffs, isMobile }) => {
  const slider = useRef<Slider>(null);
  const [activeSlide, setActiveSlide] = useState<number>(0);

  if (!tariffs) {
    return null;
  }

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    touchMove: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (_, next) => setActiveSlide(next),
  };

  const sectionClasses: string = classnames('tariffs', {
    inner: tariffs.length === 1,
    mobile: isMobile,
  });

  const getDirectionCLass = (index: number): ItemClasses => {
    const diff = index - activeSlide;
    if (tariffs.length <= 1) {
      return ItemClasses.Ltr;
    }
    return diff === 4 || diff === tariffs.length - 1 || ((!isBrowser || isIPad13) && diff === 3)
      ? ItemClasses.Rtl
      : ItemClasses.Ltr;
  };

  const tariffsList: Array<JSX.Element> = tariffs.map(
    (item: TariffsItemProps, index: number): JSX.Element => (
      <TariffsItem key={index} classes={getDirectionCLass(index)} {...item} />
    )
  );

  const changeSlide = (next?: boolean): void => {
    // eslint-disable-next-line no-unused-expressions
    next ? slider.current?.slickNext() : slider.current?.slickPrev();
  };

  if (isMobile) {
    return (
      <section className={sectionClasses} id="tariffs">
        <div className="container">
          <div className="tariffs__mobile">{tariffsList}</div>
        </div>
      </section>
    );
  }

  return (
    <section className={sectionClasses} id="tariffs">
      <div className="container">
        {tariffs.length === 1 ? (
          <TariffsItem classes={ItemClasses.Center} {...tariffs[0]} />
        ) : (
          <div className="tariffs__slider-wrap">
            {tariffs.length > 5 && (
              <div
                className={classnames('tariffs__slider-arrow', 'prev', {
                  disabled: activeSlide === 0,
                })}
                role="button"
                onClick={() => changeSlide()}
              />
            )}
            <Slider ref={slider} className="tariffs__slider" {...settings}>
              {tariffsList}
            </Slider>
            {tariffs.length > 5 && (
              <div
                className={classnames('tariffs__slider-arrow', 'next', {
                  disabled:
                    slider.current &&
                    slider.current.props.slidesToShow &&
                    // @ts-ignore
                    activeSlide === tariffs.length - (slider.current.state.breakpoint ? 2 : 5),
                })}
                role="button"
                onClick={() => changeSlide(true)}
              />
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default Tariffs;
