import { FormRequestType } from 'store/app';
import { ComponentProps as FormComponentProps } from '../../Form';

export enum PhoneErrorMessage {
  Init = '',
  Empty = 'Введите номер телефона',
  Invalid = 'Неправильный телефон',
}

export type FormViewType = {
  onSubmit: (formData: FormRequestType) => void;
};

export type ComponentProps = Omit<FormComponentProps, 'postPhone'> & FormViewType;
